/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
// import * as THREE from 'three';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { useWindowSize } from 'usehooks-ts';
import { FaStar } from 'react-icons/fa6';
import { useWindowSize } from 'usehooks-ts';
import styles from './Picture.module.scss';
// import markerImg from './images/marker.png';

import markerSvg from './images/marker.svg';
import { getImageSize } from '../../utils';
// import { useAppDispatch } from '../../reducers';

function PictureViewer({
  picture, markers, addMarker, putMarker, isAdd, getScene, isAdmin = false,
}) {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const location = useLocation();
  const viewerContainer = useRef(null);
  const contentRef = useRef(null);
  const { width } = useWindowSize();
  const [draggingMarker, setDraggingMarker] = useState(null);
  const [containerStyle, setContainerStyle] = useState({});
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [aspectRatio, setAspectRatio] = useState(1);
  const { scene } = useSelector((d) => d.scenesReducer);
  const navigate = useNavigate();

  async function handleAdd(e) {
    if (!isAdd) return;
    const rect = e.target.getBoundingClientRect();

    // Calculate the x and y coordinates relative to the image
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const pos = {
      z: 0,
      x: (x / containerStyle.width) * 100,
      y: (y / containerStyle.height) * 100,
    };
    await addMarker({ ...pos });
  }

  function handleMouseDown(e, marker) {
    e.preventDefault();
    if (!isAdmin) return;
    const rect = viewerContainer.current.getBoundingClientRect();
    const target = document.getElementById(marker._id).getBoundingClientRect();

    // Calculate the initial position relative to the image
    const x = e.clientX - rect.left - (target.width / 2);
    const y = e.clientY - rect.top - (target.height / 2);
    setStartPosition({ x: e.clientX, y: e.clientY });

    setDraggingMarker({
      ...marker,
      position: {
        x,
        y,
        z: 0,
      },
    });
  }

  function handleMarkerClick(marker) {
    // Handle the marker click event
    if (isAdmin) {
      return navigate(`/admin/${scene._id}/${marker._id}`);
    }
    return navigate(`/showroom/${scene?._id}?fiche=${marker._id}`);
    // Perform any other actions you want on marker click, like showing details
  }

  function handleMouseMove(e) {
    if (!isAdmin) return;
    if (!draggingMarker) return;
    const rect = viewerContainer.current.getBoundingClientRect();
    const target = document.getElementById(draggingMarker._id).getBoundingClientRect();

    // Calculate the new x and y coordinates relative to the image
    const x = e.clientX - rect.left - (target.width / 2);
    const y = e.clientY - rect.top - (target.height / 2);
    const movedX = Math.abs(e.clientX - startPosition.x);
    const movedY = Math.abs(e.clientY - startPosition.y);
    if (movedX > 1 || movedY > 1) {
      setDraggingMarker({ ...draggingMarker, position: { x, y, z: 0 } });
    }
  }

  async function handleMouseUp(e) {
    const movedX = Math.abs(e.clientX - startPosition.x);
    const movedY = Math.abs(e.clientY - startPosition.y);
    if (movedX < 5 && movedY < 5) {
      return handleMarkerClick(draggingMarker);
    }
    if (isAdmin) {
      if (draggingMarker) {
        const pos = {
          ...draggingMarker.position,
          x: (draggingMarker.position.x / containerStyle.width) * 100,
          y: (draggingMarker.position.y / containerStyle.height) * 100,
        };
        await putMarker(draggingMarker._id, { ...pos });
        await getScene();
      }
    }
    return setDraggingMarker(null);
  }

  const getMarkerStyle = (m) => {
    const obj = { top: `${m.position.y}%`, left: `${m.position.x}%` };
    if (draggingMarker?._id === m._id) {
      return { top: draggingMarker.position.y, left: draggingMarker.position.x, cursor: 'grab' };
    }
    return obj;
  };

  const getMargins = async () => {
    const obj = {
      width: '100%',
      height: '100%',
    };
    if (contentRef?.current && viewerContainer?.current?.style) {
      const contentBox = contentRef?.current?.getBoundingClientRect();
      const img = await getImageSize(picture);

      // Calculate the aspect ratio of the image
      const ratio = img.height / img.width;
      setAspectRatio(aspectRatio);

      // Set the container width to 100% of the content's width
      const containerWidth = contentBox.width;

      // Calculate the height based on the aspect ratio
      const containerHeight = containerWidth * ratio;

      // Update the viewerContainer's width and height
      if (viewerContainer?.current?.style) {
        viewerContainer.current.style.width = `${containerWidth}px`;
        viewerContainer.current.style.height = `${containerHeight}px`;
      }

      // Set the calculated width and height in the state or container style
      return setContainerStyle({
        width: containerWidth,
        height: containerHeight,
      });
    }
    return setContainerStyle(obj);
  };

  useEffect(() => {
    if (contentRef?.current && viewerContainer?.current?.style) {
      getMargins();
    }
  }, [contentRef?.current, width, viewerContainer?.current]);

  return (
    <div className={styles.content} ref={contentRef}>
    <div
      ref={viewerContainer}
      className={styles.container}
      style={{ ...containerStyle }}
      onClick={(e) => handleAdd(e)}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <img src={picture} style={{ ...containerStyle }} alt="bg" />
        {markers?.map((m) => <div
          key={m._id}
          id={m._id}
          className={styles.marker}
          draggable={isAdmin}
          onMouseDown={(e) => handleMouseDown(e, m)}
          onClick={(e) => {
            if (!draggingMarker) {
              handleMarkerClick(m, e);
            }
          }}
          style={getMarkerStyle(m)}
          >
            <div className={styles.content}
              id={`label-${m._id}`}
              style={{ maxWidth: 300 }}
            >
              <img src={markerSvg} alt={m.name}/>
              <h2>{m.isNew && <span><FaStar />{' '}</span> }{m.name}</h2>
            </div>
          </div>)}
        </div>
    </div>
  );
}

export default PictureViewer;
