import React, {
  useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { AiTwotoneSave } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { BiLink } from 'react-icons/bi';
import back from './images/back.svg';
// import deleteFile from '../../actions/file';
import {
  getSceneAction,
  getLandmarkAction,
  putLandmarkAction,
  deleteLandmarkAction,
} from '../../actions/scene';
import Loader from '../../components/Loader';

import { useAppDispatch } from '../../reducers';
import styles from './EditLandmark.module.scss';
import InputFile from '../../components/InputFile/InputFile';
import deleteFile from '../../actions/file';
import { Tab } from '../../types/scene';
import TabForm from '../../components/TabForm';
import { SET_TOAST } from '../../actions/types';

const InputName = () => {
  const { register } = useFormContext(); // retrieve all hook methods
  return <input {...register('name')} />;
};

export default function EditLandmark() {
  const { sceneId, landmarkId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm();
  const { landmark } = useSelector((d: any) => d.scenesReducer);
  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState,
  } = methods;

  const { isDirty } = formState;

  const onSubmit = async (data: any) => {
    await putLandmarkAction(dispatch, landmark._id, data);
  };

  const getLandmark = useCallback(() => {
    if (sceneId && landmarkId) {
      getSceneAction(dispatch, sceneId);
      getLandmarkAction(dispatch, landmarkId);
    }
  }, [dispatch, sceneId, landmarkId]);

  const media = landmark?.media?.path && `${process.env.REACT_APP_API_URL}/public/${landmark.media.path}`;

  async function handleChangeMedia(id: string) {
    if (!landmarkId) {
      return null;
    }
    if (landmark?.media?._id) {
      await deleteFile(dispatch, landmark?.media?._id);
    }
    return putLandmarkAction(dispatch, landmarkId, { ...watch(), media: id });
  }

  async function deleteMedia() {
    if (!landmarkId) {
      return null;
    }
    if (landmark?.media?._id) {
      await deleteFile(dispatch, landmark?.media?._id);
    }
    return putLandmarkAction(dispatch, landmarkId, { ...watch(), media: null });
  }

  async function deleteLandmark() {
    // eslint-disable-next-line no-alert
    if (window.confirm('Souhaitez-vous supprimer ce point de repère')) {
      const res = await deleteLandmarkAction(dispatch, landmark._id);
      if (res) {
        navigate(`/admin/${sceneId}`);
      }
    }
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_API_URL}/redirect?referer=b@k@s@ble&showroom=${sceneId}&fiche=${landmarkId}`);
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'L\'url de la page a été copie',
      },
    });
  };

  useEffect(() => {
    getLandmark();
  }, [landmarkId]);

  useEffect(() => {
    if (landmark) {
      reset(landmark);
    }
  }, [landmark]);

  return (
    <div className={styles.container}>
      {landmark
        ? <>
          <Link to={`/admin/${sceneId}`}>
            <img src={back} alt='back' />
            RETOUR
          </Link>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <header>
                <div className={styles.name}>
                  <InputName />
                </div>
                <div className={styles.actions}>
                  <button
                    className={styles.icon}
                    onClick={() => copyUrl()}
                  >
                    <BiLink />
                  </button>
                  <button
                    type="button"
                    className={styles.icon}
                    onClick={() => deleteLandmark()}
                  >
                    <RiDeleteBin6Fill />
                  </button>
                  <button
                    type='submit'
                    className={`${styles['with-icon']} ${!isDirty && styles.saved}`}
                  >
                    {isDirty ? <AiTwotoneSave /> : <HiCheck />}
                    Sauvegarder
                  </button>
                </div>
              </header>
              <div className={styles.new}>
                <h4>Nouveauté</h4>
                <button
                  type="button"
                  className={`${styles.switch} ${watch('isNew') ? styles.active : ''}`}
                  onClick={() => setValue('isNew', !watch('isNew'))}
                >
                  <p>On</p>
                  <p>Off</p>
                  <div className={styles.round}/>
                </button>
              </div>
              <div className={styles['select-tabs']}>
                <button
                  type='button'
                  className={watch('tabsCount') === 1 ? styles.primary : ''}
                  onClick={() => setValue('tabsCount', 1)}
                >
                  Fiche simple
                </button>
                <button
                  type='button'
                  className={watch('tabsCount') === 2 ? styles.primary : ''}
                  onClick={() => setValue('tabsCount', 2)}
                >
                  Fiche 2 onglets
                </button>
              </div>
              <div className={styles.cover}>
                {media
                  ? <div className={styles.media}>
                      {(landmark.media.mimetype === 'image/png' || landmark.media.mimetype === 'image/jpeg')
                        && <img src={media} alt="cover"/>
                      }
                      {(landmark.media.mimetype === 'video/mp4')
                        && <video controls>
                          <source src={media} type="video/mp4" />
                          <p>
                            Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
                            <a href={media}>un lien pour télécharger la vidéo</a>.
                          </p>
                        </video>
                      }
                      <button
                        type="button"
                        className={styles.icon}
                        onClick={() => deleteMedia()}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                  </div>
                  : <div className={styles.file}>
                    <InputFile
                      id={landmark._id}
                      className={'cover'}
                      submit={(fileId: string) => handleChangeMedia(fileId)}
                      btnText={'Ajouter une illustration principale'}
                    />
                </div>
                }
              </div>
              {landmark.tabs?.filter((t: Tab, i: number) => i < watch('tabsCount')).map((t: Tab, i: number) => (
                <div
                  key={t._id}
                  className={styles.content}>
                  <div className={styles.tab}>
                    <TabForm
                      tabIndex={i}
                      isUniqueTab={watch('tabsCount') === 1}
                      tab={watch('tabs')[i]}
                      saveLandmark={(data: any) => onSubmit(data)}
                    />
                  </div>
                </div>
              ))}
            </form>
          </FormProvider>
        </>
        : <div className={styles.loader}>
          <Loader />
        </div>
      }
    </div>
  );
}
