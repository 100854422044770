import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-white.png';
import styles from './home.module.scss';

const Home = () => {
  const { user } = useSelector((d: any) => d.authReducer);
  const { scenes } = useSelector((d: any) => d.scenesReducer);
  const location = useLocation();
  const url = user?.role === 'Admin' && scenes?.length > 0 ? `/admin/${scenes[0]?._id}${location.search}` : '/login';

  return (
    <div className={styles.container}>
      <Link to={url} className={styles.logo}>
        <img src={logo} alt="logo-super-u" />
      </Link>
      <div className={styles.content}>
        <h1>
          Bienvenue dans notre showroom<br className={styles.mobile}/>{' '} bazar et services,<br/>
          vitrine d’un magasin <br className={styles.mobile}/>de 3500m² attraction (rural)
        </h1>
        <p>
          Passage recommandé dans le cadre d’un projet d’agrandissement ou d’optimisation
        </p>
        <p>
          Outil incontournable dans tous les parcours de formation,<br/>
          reflet des assortiments à jour avec toutes les dernières gammes de produits U
        </p>
        <p>
          Mise en situation des derniers concepts, mobiliers et signalétiques
        </p>
        <p>
          Une réponse à toutes vos aspirations spécifiques, avec des idées de théâtralisation,
          des tiroirs d’offre et des offres complémentaires<br/>
        </p>
        <Link to={`/showroom${location.search}`} className={styles.showroom}>
          Entrer dans le showroom
        </Link>
      </div>
    </div>
  );
};

export default Home;
