/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-white.png';
import styles from './oups.module.scss';

const Oups = ({ isMobile = false }: { isMobile?: boolean }) => {
  const location = useLocation();
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo-super-u" />
      </div>
      <div className={styles.content}>
        <h1>
          Oops !
        </h1>
        <p>
          vous n'avez pas accès à cette page
          {isMobile && <> sur cet appareil</>}
        </p>
        {isMobile
          && <Link to={`/showroom${location.search}`} className={styles.showroom}>
          Entrer dans le showroom
        </Link>
        }
      </div>
    </div>
  );
};

export default Oups;
