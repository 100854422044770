export function getSearchObject(searchString) {
  if (!searchString) {
    return {

    };
  }
  const search = searchString?.substring(1);
  return JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
}

export async function getImageSize(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    img.onerror = (err) => {
      reject(err);
    };
  });
}
