import React, { useCallback, useEffect } from 'react';
import {
  Routes, Route, useSearchParams, useNavigate, useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { getProfileAction } from './actions/auth';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useWindowSize } from 'usehooks-ts';
import Layout from './components/Layout';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import Oups from './pages/oups';
import ResetPassword from './pages/reset-password';
import Loader from './components/Loader';
import Toaster from './lib/Toaster';
import Home from './pages/home';
import EditScene from './pages/editScene';
import EditLandmark from './pages/editLandmark';
import Showroom from './pages/showroom';
import Scene from './pages/scene';
import { getScenesAction, getScenesListsAction } from './actions/scene';
import { createHistoryAction, getProfileAction } from './actions/auth';

const Redirect = () => {
  const searchParams = useSearchParams()[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleAuth() {
    const urlToken = searchParams.get('token');
    if (urlToken) {
      await localStorage.setItem('token', `JWT ${urlToken}`);
    }
    await getProfileAction(dispatch);
    if (!urlToken) {
      // no auth route;
      navigate('/401');
    }
  }

  useEffect(() => {
    handleAuth();
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { authReducer } = useSelector((store: any) => store);
  const { width } = useWindowSize();
  const location = useLocation();

  const dispatch = useDispatch();
  const { user, previous } = authReducer;

  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const landmark = searchParams.get('fiche');

  async function handleAuth() {
    const urlToken = searchParams.get('token');
    if (urlToken) {
      await localStorage.setItem('token', `JWT ${urlToken}`);
    }
    await getProfileAction(dispatch);
    if (!urlToken) {
      // no auth route;
      navigate('/401');
    }
  }

  const handleLastHistory = useCallback((event: any, prev: any) => {
    if (prev) {
      createHistoryAction(dispatch, {
        previous: prev,
      });
    }
    return '';
  }, []);

  useEffect(() => {
    handleAuth();
  }, []);

  useEffect(() => {
    if (user?.role) {
      getScenesListsAction(dispatch);
      getScenesAction(dispatch);
    }
  }, [user]);

  useEffect(() => {
    if (user?.ip) {
      const splitedPath = location.pathname.split('/');
      if (splitedPath.length === 3 && splitedPath.find((p) => p === 'showroom') && !splitedPath.find((p) => p === 'edit')) {
        const scene = splitedPath[2];
        createHistoryAction(dispatch, {
          ip: user.ip,
          scene,
          landmark,
          previous,
        });
      }
    }
  }, [location.pathname, user, landmark]);

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => handleLastHistory(e, previous));
    return () => {
      window.removeEventListener('beforeunload', (e) => handleLastHistory(e, previous));
    };
  }, [previous]);

  return (
    <>
      <Routes>
        <Route path="/401" element={<Oups />} />
        {user?.role
          && <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={width > 1024 ? <SignIn /> : <Oups isMobile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/showroom/edit" element={<Showroom />} />
            <Route path="/showroom/:sceneId" element={<Scene />} />
            <Route path="/showroom" element={<Showroom isNav={true} />} />
            {user?.role === 'Admin' && (
              <Route
                path="*"
                element={width > 1024 ? (
                  <Layout role={user?.role}>
                    <Routes>
                      <Route path="/admin/:sceneId/:landmarkId" element={<EditLandmark />} />
                      <Route path="/admin/:sceneId" element={<EditScene />} />
                    </Routes>
                  </Layout>
                ) : <Oups isMobile />}
              />
            )}
        </>
        }
        <Route path="*" element={<Redirect />} />
      </Routes>
      <Toaster />
    </>
  );
};

export default AppRoutes;
